/**
 * External dependencies
 */
import React, {
    useState,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from "react";
import $ from "jquery";

/**
 * Internal dependencies
 */
import { createApi } from "../../cart/local-storage-api";
import { getDetails } from "@/frontend/domain/cart/ajax";
import BtnClose from "./btn-close";
import { $window } from "@/frontend/utils/globals";

$(function() {
    $(".js-popup-cart").on("click", function(event) {
        if ($window.width() <= 768) {
            return;
        }

        event.preventDefault();
        const $popupCart = $(this).siblings().find(".cart-popup");
        $popupCart.addClass("is-active");
    });

    $window.on("load resize", function() {
        if ($window.width() > 768) {
            return;
        }

        $(".cart-popup").removeClass("is-active");
    });
});

const CartPopup = () => {
    const [products, setProducts] = useState([]);
    const [prices, setPrices] = useState({
        subTotal: 0,
        tax: 0,
        total: 0,
    });
    const storage = useMemo(() => createApi(), []);
    const popup = useRef(null);

    const fetchAndSetProducts = useCallback(async () => {
        const res = await getDetails();

        if (res.success) {
            setProducts(() =>
                Object.values(res.data?.groupedItems || []).flat(),
            );

            setPrices({
                subTotal: res.data.subTotal,
                tax: res.data.tax,
                total: res.data.itemTotal,
            });
        } else {
            setPrices({
                subTotal: 0,
                tax: 0,
                total: 0,
            });
        }
    }, []);

    useEffect(() => {
        (async () => {
            // Listen for storage events
            storage.on("add", async () => {
                await fetchAndSetProducts();
            });

            storage.on("removed", async () => {
                await fetchAndSetProducts();
            });

            await fetchAndSetProducts();
        })();

        return () => storage.removeAllEventListeners();
    }, []);

    const removeProduct = (productId, index) => {
        return () => {
            storage.removeAllItemsWithId(productId);

            setProducts((currentProducts) => {
                return currentProducts.filter((_, idx) => idx !== index);
            });
        };
    };

    const close = (event) => {
        event.preventDefault();
        if (!popup.current) return;
        popup.current.classList.remove("is-active");
    };

    return (
        <div className="cart-popup" ref={popup}>
            <div className="cart__inner">
                <BtnClose
                    href="#"
                    className="btn-close cart__close js-popup-close"
                    onClick={close}
                />

                {products?.length === 0 ? (
                    <span>The cart is empty</span>
                ) : (
                    products?.map(({ productId, product, count }, index) => (
                        <div
                            className="cart__product"
                            key={`${product.id}-${index}`}
                        >
                            <div className="cart__image">
                                <img
                                    src={product.image}
                                    alt=""
                                    width="79"
                                    height="101"
                                />
                            </div>

                            <div className="cart__content">
                                <div className="cart__body">
                                    <p>
                                        {product.name} - {product.business}
                                    </p>

                                    <p className="price cart__price">
                                        {count > 1 && (
                                            <span className="price__quantity">
                                                Quantity: {count}
                                            </span>
                                        )}

                                        <span className="price__old">
                                            ${product.retailValue}
                                        </span>
                                        <span className="price__new">
                                            ${product.price}
                                        </span>
                                    </p>
                                </div>

                                <BtnClose
                                    className="cart__remove btn-close"
                                    width={12}
                                    height={12}
                                    onClick={removeProduct(productId, index)}
                                />
                            </div>
                        </div>
                    ))
                )}
            </div>

            <div className="cart__total">
                <span>Subtotal</span>

                <strong>${prices.subTotal}</strong>
            </div>

            <div className="cart__actions">
                <a
                    href="/cart"
                    className="btn btn--medium btn--transparent btn--full cart__btn"
                >
                    View Cart
                </a>
            </div>
        </div>
    );
};

export default CartPopup;
